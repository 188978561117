import { Badge } from "@mantine/core";
import Translate from "Components/Translate";
import { STATUS } from "Constant";
import { getPurchaseRequests } from "helper/getStatusColor";

export const activeObj = {
  name: "active",
  title: "Active",
  inputForm: "SelectInput",
  options: [
    {
      value: "",
      label: "all",
      name: "active",
    },
    {
      value: "true",
      label: "active",
      name: "active",
    },
    {
      value: "false",
      label: "not active",
      name: "active",
    },
  ],
};

export const civilityObj = {
  name: "civility",
  title: "Civility",
  inputForm: "SelectInput",
  options: [
    {
      value: "M",
      label: "Mr",
      name: "civility",
    },
    {
      value: "MME",
      label: "Mrs",
      name: "civility",
    },
  ],
};

export const captureObj = {
  title: "Capture capture",
  name: "clientCapture",
  inputForm: "SelectInput",
  options: [
    {
      value: "FB",
      label: "Facebook",
      name: "capture",
    },
    {
      value: "Instagram",
      label: "Instagram",
      name: "capture",
    },
    {
      value: "Tiktok",
      label: "Tiktok",
      name: "capture",
    },
    {
      value: "Email",
      label: "Email",
      name: "capture",
    },
    {
      value: "Events",
      label: "Events",
      name: "capture",
    },
    {
      value: "Mouth to ear",
      label: "Mouth to ear",
      name: "capture",
    },
    {
      value: "Others",
      label: "Others",
      name: "capture",
    },
  ],
};

export const saleOrTry = {
  name: "type",
  title: "Type",
  inputForm: "SelectInput",
  options: [
    {
      value: "sale",
      label: "Sale",
    },
    {
      value: "try",
      label: "Try",
    },
  ],
};

export const createdAt = { name: "Created at", selector: "created_at" };
export const updatedAt = { name: "Updated At", selector: "updated_at" };

export const statusObj = {
  accessorFn: (originalRow) => originalRow.status,
  id: "status",
  header: STATUS,

  filterFn: "equals",
  filterVariant: "select",
  mantineFilterSelectProps: {
    data: [
      { value: "canceled", label: "Annulé" },
      { value: "draft", label: "Brouillon" },
      { value: "confirmed", label: "Confirmé" },
      { value: "paid", label: "Payé" },
      { value: "created", label: "Crée" },
      { value: "ready", label: "Prêt" },
    ],
  },

  Cell: ({ row }) => {
    const { status } = row.original;
    return (
      <Badge color={getPurchaseRequests(status)} size="lg">
        {status}
      </Badge>
    );
  },
};
