const parsePrice = (price) => parseFloat(price) || 0;
const parseQantity = (price) => parseFloat(price) || 1;

const getEstiamteCoefficient = (param) => {
  try {
    const data = localStorage.getItem("userData");

    // If the data is JSON, parse it
    const parsedData = JSON.parse(data);
    const { estimate_coefficient } = parsedData;
    console.log(estimate_coefficient);

    let key = 0;

    if (param == 3) key = 3;
    else if (param > 3 && param <= 6) key = 6;
    else if (param > 6) key = 12;

    return estimate_coefficient[key];
  } catch (error) {
    console.error("An error occurred:", error);
    // Return 0 in case of any errors
    return 0;
  }
};

export const calculateProduct = (price_ht = 0, vat, quantity, discount = 0) => {
  // Ensure price_ht, vat, quantity, and discount are valid numbers
  const validPrice = isNaN(price_ht) ? 0 : parseFloat(price_ht);
  const validVat = isNaN(vat) ? 0 : parseFloat(vat);
  const validQuantity = isNaN(quantity) ? 1 : parseFloat(quantity);
  const validDiscount = isNaN(discount) ? 0 : parseFloat(discount);

  // Calculate the price after discount
  const priceAfterDiscount = validPrice - validPrice * (validDiscount / 100);

  // Calculate VAT and the total price with VAT (price TTC)
  const calculateVat = priceAfterDiscount * (validVat / 100);
  const priceVat = priceAfterDiscount + calculateVat;
  const priceTtc = priceVat * validQuantity;

  const roundedPriceTtc =
    priceTtc % 1 >= 0.5 ? Math.ceil(priceTtc) : Math.floor(priceTtc);
  const withoutRoundedPriceTtc = priceTtc.toFixed(2);

  return validVat ? roundedPriceTtc : withoutRoundedPriceTtc;
};

export const calculatePrice = (products, services, timescale) => {
  console.log(timescale);
  const estiamteCoefficient = getEstiamteCoefficient(timescale) / 100 + 1;

  console.log(estiamteCoefficient);

  const result = {
    vatAmount: 0,
    price: 0,
    priceVat: 0,
  };

  products.forEach((prod) => {
    result.price = parsePrice(prod.price) + result.price;
    result.priceVat = parsePrice(prod.price_ttc) + result.priceVat;
  });

  services.forEach((serve) => {
    result.price = parsePrice(serve.price) + result.price;
    result.priceVat = parsePrice(serve.price_ttc) + result.priceVat;
  });

  result.vatAmount = result.priceVat - result.price;

  return {
    vatAmount: (result.vatAmount * estiamteCoefficient).toFixed(1),
    price: (result.price * estiamteCoefficient).toFixed(1),
    priceVat: (result.priceVat * estiamteCoefficient).toFixed(1),
  };
};
