import {
  Accordion,
  Avatar,
  Center,
  Group,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconTool } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import { EQUIPEMENT, UPDATE_EQUIPMENT } from "Constant";

import Translate from "Components/Translate";

import { isEmpty } from "./isEmpty";
import UpdateBtn from "./UpdateBtn";

const EquipmentsComp = ({ data }) => {
  if (isEmpty(data)) return isEmpty(data);

  const {
    box,
    luminous,
    printer,
    sunshield,
    sos_button,
    stylus,
    relay_switch,
    sensor,
    obd_adapter,
    bracket,
  } = data;

  const requiredKeys = [
    { title: "Box", key: box?.serial_number_box },
    { title: "Luminous", key: luminous?.serial_number_luminous },
    { title: "Printer", key: printer?.serial_number_printer },
    { title: "Sunshield", key: sunshield?.serial_number_sunshield },
    { title: "SOS Button", key: sos_button?.serial_number_sos_button },
    { title: "Stylus", key: stylus?.serial_number_stylus },
    { title: "Relay Switch", key: relay_switch?.serial_number_relay_switch },
    { title: "Sensor", key: sensor?.serial_number_sensor },
    { title: "OBD Adapter", key: obd_adapter?.serial_number_obd_adapter },
    { title: "Bracket", key: bracket?.serial_number_bracket },
  ];

  const urlUpdate = "";

  return (
    <Fragment>
      <Accordion
        chevronPosition="left"
        variant="contained"
        defaultValue={["client"]}
      >
        <Accordion.Item value={"client"} key={0}>
          <Center>
            <Accordion.Control>
              <Group wrap="nowrap">
                <Avatar radius="xl" size="lg" color="orange">
                  {<IconTool color="black" />}
                </Avatar>

                <Space w="md" />

                <div>
                  <Text size="xl" fw={700}>
                    <Translate>{EQUIPEMENT}</Translate>
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                  </Text>
                </div>
              </Group>
            </Accordion.Control>
            {/* <TaxiSettings data={data} /> */}
            <Space w="lg" />
          </Center>

          <Accordion.Panel>
            <Space h="md" />
            <Paper shadow="xs" withBorder p="xl">
              <SimpleTable requiredKeys={requiredKeys} />
              <UpdateBtn title={UPDATE_EQUIPMENT} path={urlUpdate} />
            </Paper>
            <Space h="md" />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default EquipmentsComp;
