import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Importing uuid for unique ID generation

import EmptyData from "Components/EmptyData";
import Loading from "Components/Loading/Loading";

import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";
import CreateEstimatePage from "pages/CreateEstimatePage.test";

import { useDispatch } from "react-redux";
import { fillOldData } from "../../redux/estimateSlicer";

const EditEstimate = () => {
  const { code } = useParams();
  const url = `/back/estimate/${code}`;
  const dispatch = useDispatch();

  const { data, isLoading, isError } = useFetchData(url);
  useEffect(() => {
    if (!isLoading) dispatch(fillOldData(getOldData(data)));
  }, [isLoading]);

  if (isLoading) return <Loading />;

  if (isError) return <EmptyData />;

  const oldData = getData(data);

  const oldClient = oldData ? oldData.client : "";
  const oldTaxi = oldData ? oldData.taxi : "";
  const oldEstimateD = oldData
    ? {
        timescale: oldData.timescale,
        remainingAmount: oldData.remainingAmount,
        deposit: oldData.deposit,
        taxi: oldTaxi,
      }
    : { timescale: 0, remainingAmount: 0, deposit: 0 };

  return (
    <CreateEstimatePage
      oldData={oldData}
      oldClient={oldClient}
      oldEstimateD={oldEstimateD}
      url={url}
    />
  );
};

export default EditEstimate;

const getOldData = (res) => {
  const data = res.data.results.data;
  console.log(data);

  const products = data.products.map((elem) => ({
    id: uuidv4(),
    category: elem.category,
    model: elem.model,
    description: elem.description,
    discount: elem.discount,
    price: elem.unit_price,
    quantity: elem.quantity,
    vat: elem.vat,
  }));

  const services = data.services.map((elem) => ({
    id: uuidv4(),
    service: elem.service,
    description: elem.description,
    price: elem.unit_price,
    quantity: elem.quantity,
    vat: elem.vat,
  }));

  const result = {
    timescale: data.timescale,
    remainingAmount: data.remainingAmount,
    deposit: data.deposit,
    number: data.estimateNumber,
    taxi: data.taxi,
    client: data.client,
    vatAmount: data.vatAmount,
    price: data.price,
    priceVat: data.priceVat,
    products: products,
    services: services,
  };
  return result;
};
