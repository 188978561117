import { TOTAL, TTC } from "Constant";
import { calculateProduct } from "helper/estimateFx";

export const testStatus = (status) => {
  if (status === "canceled") {
    return false;
  }
  if (status === "draft") {
    return true;
  }
  if (status === "confirmed") {
    return false;
  }
  if (status === "paid") {
    return false;
  }
  if (status === "created") {
    return true;
  }
  if (status === "ready") {
    return false;
  }

  return true;
};

export const getRowRes = (data) => {
  try {
    const res = data.data.results.data;
    return res;
  } catch {
    return {};
  }
};

export const columnsProd = [
  { header: "Catégorie", field: "category" },
  { header: "Description", field: "description" },
  { header: "Quantité", field: "quantity" },
  { header: "Réduction %", field: "discount" },
  { header: "Prix ht", field: "unit_price" },
  { header: "TVA %", field: "vat" },
  {
    header: TTC,
    field: (rowData) => {
      const { unit_price, vat } = rowData;

      return calculateProduct(unit_price, vat);
    },
  },
];

export const columnsServ = [
  { header: "Service", field: "service_label" },
  { header: "Description", field: "description" },
  { header: "Quantité", field: "quantity" },
  { header: "Réduction %", field: "discount" },
  { header: "Prix ht", field: "unit_price" },
  { header: "TVA %", field: "vat" },
  {
    header: TTC,
    field: (rowData) => {
      const { unit_price, vat } = rowData;

      return calculateProduct(unit_price, vat);
    },
  },
];

export const estimateDetailsColumns = [
  { header: "Dépôt", field: "deposit" },
  { header: "Montant à échloner", field: "remainingAmount" },
  { header: "Échéancier", field: "timescale" },
];
