import { Group } from "@mantine/core";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import {
  CLIENT,
  CREATED_AT,
  DETAILS,
  ERROR,
  MESSAGE,
  RESOLVED,
  SERIAL_NUMBER,
  TAXI,
  TAXIMETER,
  UPDATED_AT,
} from "Constant";

export const API = {
  list: "/back/taximeters/problems",
};

// Columns
export const COLUMNS = [
  {
    header: CLIENT,
    size: 300,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",

    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: TAXI,
    size: 300,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",

    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },

  {
    name: TAXIMETER,
    selector: "taximeter",
  },
  {
    name: SERIAL_NUMBER,
    selector: "serial_number",
  },
  {
    name: ERROR,
    selector: "error",
  },
  {
    name: MESSAGE,
    selector: "message",
  },

  {
    header: RESOLVED,
    id: "actions",
    button: "true",
    // size: 80,
    Cell: ({ row }) => {
      const { code, resolved } = row.original;
      const patchUrl = `/back/problem/${code}`;
      return (
        <Group gap="xs">
          <ActiveBtn patch={patchUrl} state={resolved} />
        </Group>
      );
    },
  },

  {
    name: DETAILS,
    selector: "details",
  },
  {
    name: CREATED_AT,
    selector: "created_at",
  },
  {
    name: UPDATED_AT,
    selector: "updated_at",
  },
];
